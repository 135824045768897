import React from 'react';
import { Button } from 'flowbite-react';

interface Bot {
  name: string;
  description: string;
  features: string[];
  inviteUrl: string;
  imageUrl: string;
}

const bots: Bot[] = [
  {
    name: 'NxP Guard',
    description: 'NxP Guard is a powerful moderation bot for Discord that helps protect your server. With features like user reporting, banning, blacklisting, and automated moderation, NxP Guard ensures a safe environment. All reports are reviewed by a dedicated moderation team to enforce server rules and manage problematic users with automated actions like banning and unbanning.',
    features: [],
    inviteUrl: 'https://discord.com/oauth2/authorize?client_id=1306549990461669407',
    imageUrl: 'https://noxitech.dk/cdn/images/nxpguard.png'
  },
];

interface DiscordBotsProps {
  darkMode: boolean;
}

export const DiscordBots: React.FC<DiscordBotsProps> = ({ darkMode }) => {
  return (
    <div className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'}`}>
      <div className="w-full max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className={`text-3xl font-extrabold ${darkMode ? 'text-white' : 'text-gray-900'} sm:text-4xl`}>
            Discover Our Powerful Discord Bots
          </h2>
          <p className={`mt-4 text-xl ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Enhance your Discord server with our powerful and reliable bots
          </p>
        </div>
        <div className="mt-16 flex flex-wrap justify-center gap-8">
          {bots.map((bot, index) => (
            <div key={index} className={`max-w-sm rounded-lg overflow-hidden shadow-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} p-6 transition-transform duration-300 hover:scale-102`}>
              <div className="flex justify-center">
                <img src={bot.imageUrl} alt={bot.name} className="h-48 w-full object-cover rounded-2xl" />
              </div>
              <div className="px-1 py-4">
                <h5 className={`text-2xl font-bold tracking-tight ${darkMode ? 'text-white' : 'text-gray-900'} mb-3`}>
                  {bot.name}
                </h5>
                <p className={`font-normal ${darkMode ? 'text-gray-400' : 'text-gray-700'} mb-4`}>
                  {bot.description}
                </p>
                <div className="mt-4">
                  <Button color={darkMode ? "zinc" : "gray"} href={bot.inviteUrl} className={`w-full ${darkMode ? 'bg-zinc-700 hover:bg-zinc-600' : 'bg-zinc-600 hover:bg-zinc-500'} text-white transition-colors duration-300`}>
                    Invite {bot.name}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};