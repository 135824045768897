import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MarkdownToolbar } from '../common/MarkdownToolbar';

interface TermsSection {
  id: number;
  title: string;
  content: string;
  order: number;
}

interface TermsManagerProps {
  darkMode: boolean;
}

export const TermsManager: React.FC<TermsManagerProps> = ({ darkMode }) => {
  const [sections, setSections] = useState<TermsSection[]>([]);
  const [editingSection, setEditingSection] = useState<TermsSection | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await axios.get('https://api.nxpdev.dk/terms/sections');
      setSections(response.data);
    } catch (error) {
      setError('Failed to fetch terms sections');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (section: TermsSection) => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        throw new Error('Missing authentication credentials');
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      };

      if (section.id) {
        await axios.put(`https://api.nxpdev.dk/terms/sections/${section.id}`, section, config);
      } else {
        await axios.post('https://api.nxpdev.dk/terms/sections', section, config);
      }
      
      fetchSections();
      setEditingSection(null);
    } catch (error) {
      setError('Failed to save section');
      console.error('Error:', error);
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this section?')) {
      try {
        const token = localStorage.getItem('discord_token');
        const apiKey = process.env.REACT_APP_API_KEY;

        await axios.delete(`https://api.nxpdev.dk/terms/sections/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey
          }
        });
        
        fetchSections();
      } catch (error) {
        setError('Failed to delete section');
        console.error('Error:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          Terms of Service Management
        </h2>
        <button
          onClick={() => setEditingSection({
            id: 0,
            title: '',
            content: '',
            order: sections.length
          })}
          className={`px-4 py-2 rounded-lg ${
            darkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white transition-colors`}
        >
          <i className="fas fa-plus mr-2"></i>
          Add Section
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {editingSection ? (
        <div className={`p-6 rounded-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-lg`}>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSave(editingSection);
          }}>
            <div className="space-y-4">
              <div>
                <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Title
                </label>
                <input
                  type="text"
                  value={editingSection.title}
                  onChange={(e) => setEditingSection({ ...editingSection, title: e.target.value })}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    darkMode 
                      ? 'bg-zinc-700 border-zinc-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  required
                />
              </div>
              
              <div>
                <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Order
                </label>
                <input
                  type="number"
                  value={editingSection.order}
                  onChange={(e) => setEditingSection({ ...editingSection, order: parseInt(e.target.value) })}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    darkMode 
                      ? 'bg-zinc-700 border-zinc-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  required
                />
              </div>

              <div>
                <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Content (Markdown supported)
                </label>
                <MarkdownToolbar
                  textAreaId="terms-content"
                  darkMode={darkMode} 
                />
                <textarea
                  id="terms-content"
                  value={editingSection.content}
                  onChange={(e) => setEditingSection({ ...editingSection, content: e.target.value })}
                  rows={6}
                  className={`w-full p-2 rounded-b border-x border-b ${
                    darkMode 
                      ? 'bg-zinc-700 border-zinc-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  required
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setEditingSection(null)}
                  className={`px-4 py-2 rounded-lg ${
                    darkMode 
                      ? 'bg-zinc-700 hover:bg-zinc-600 text-white' 
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                  }`}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="space-y-6">
          {sections.map((section) => (
            <div
              key={section.id}
              className={`p-6 rounded-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-lg`}
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setEditingSection(section)}
                    className={`p-2 rounded ${
                      darkMode ? 'hover:bg-zinc-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    onClick={() => handleDelete(section.id)}
                    className={`p-2 rounded ${
                      darkMode ? 'hover:bg-zinc-700' : 'hover:bg-gray-100'
                    } text-red-500`}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              
              <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none`}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {section.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}; 