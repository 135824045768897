import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'flowbite-react';

interface UnblacklistModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  darkMode: boolean;
}

const UnblacklistModal: React.FC<UnblacklistModalProps> = ({ isOpen, onClose, onSubmit, darkMode }) => {
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    onSubmit(reason);
    setReason('');
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                  Request Unblacklist
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm">
                    Please provide a reason for your unblacklist request:
                  </p>
                  <textarea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter your reason"
                    className={`w-full mt-2 p-3 border rounded-lg shadow-sm transition duration-200 ease-in-out ${
                      darkMode ? 'bg-gray-800 text-white border-gray-600 focus:ring-blue-500' : 'bg-white text-gray-900 border-gray-300 focus:ring-blue-500'
                    }`}
                    rows={4}
                  />
                </div>

                <div className="mt-4 flex justify-end">
                  <Button color="gray" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="red" onClick={handleSubmit} disabled={!reason.trim()} className="ml-2">
                    Submit Request
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UnblacklistModal; 