import React from 'react';

interface MarkdownToolbarProps {
  textAreaId: string;
  darkMode: boolean;
}

export const MarkdownToolbar: React.FC<MarkdownToolbarProps> = ({ textAreaId, darkMode }) => {
  const insertMarkdown = (prefix: string, suffix: string = '') => {
    const textarea = document.getElementById(textAreaId) as HTMLTextAreaElement;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;
    const selectedText = text.substring(start, end);
    
    const newText = text.substring(0, start) + 
                   prefix + selectedText + suffix + 
                   text.substring(end);
    
    textarea.value = newText;
    textarea.focus();
    textarea.setSelectionRange(start + prefix.length, end + prefix.length);
  };

  const buttons = [
    { icon: 'fa-bold', action: () => insertMarkdown('**', '**'), tooltip: 'Bold' },
    { icon: 'fa-italic', action: () => insertMarkdown('*', '*'), tooltip: 'Italic' },
    { icon: 'fa-link', action: () => insertMarkdown('[', '](url)'), tooltip: 'Link' },
    { icon: 'fa-list', action: () => insertMarkdown('- '), tooltip: 'List' },
    { icon: 'fa-heading', action: () => insertMarkdown('### '), tooltip: 'Heading' },
    { icon: 'fa-code', action: () => insertMarkdown('`', '`'), tooltip: 'Code' },
    { icon: 'fa-quote-right', action: () => insertMarkdown('> '), tooltip: 'Quote' }
  ];

  return (
    <div className={`flex gap-2 p-2 rounded-t ${
      darkMode ? 'bg-zinc-700 border-zinc-600' : 'bg-gray-100 border-gray-300'
    } border-b`}>
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={(e) => {
            e.preventDefault();
            button.action();
          }}
          title={button.tooltip}
          className={`p-2 rounded hover:bg-opacity-80 transition-colors ${
            darkMode ? 'text-gray-300 hover:bg-zinc-600' : 'text-gray-700 hover:bg-gray-200'
          }`}
        >
          <i className={`fas ${button.icon}`}></i>
        </button>
      ))}
    </div>
  );
}; 