'use client'

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { PageTransition } from './components/PageTransition';
import { Landing } from './components/Landing';
import { Component as Navbar } from './components/navbar';
import { DiscordBots } from './components/DiscordBots';
import { Dashboard } from './components/Dashboard';
import { AuthCallback } from './components/AuthCallback';
import { StaffDashboard } from './components/StaffDashboard';
import { NotFound } from './components/NotFound';
import { AdminDashboard } from './components/AdminDashboard';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { Footer } from './components/Footer';
import { TermsOfService } from './components/TermsOfService';
import { AlertProvider } from './contexts/AlertContext';
import Alert from './components/Alert';
import axios from 'axios';

const AdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const token = localStorage.getItem('discord_token');
        const apiKey = process.env.REACT_APP_API_KEY;

        if (!token || !apiKey) {
          navigate('/dashboard');
          return;
        }

        const response = await axios.get('https://api.nxpdev.dk/auth/admin', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey
          }
        });

        if (!response.data.isAdmin) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/dashboard');
      } finally {
        setIsChecking(false);
      }
    };

    checkAdmin();
  }, [navigate]);

  if (isChecking) {
    return null;
  }

  return <>{children}</>;
};

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(prefersDarkMode);

    const handleChange = (event: MediaQueryListEvent) => {
      setDarkMode(event.matches);
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return (
      <AlertProvider>
        <Router>
          <div className="flex flex-col min-h-screen">
            <Navbar darkMode={darkMode} />
            <main className="flex-grow">
              <PageTransition>
                <Routes>
                  <Route path="/" element={<Landing darkMode={darkMode} />} />
                  <Route path="/bots" element={<DiscordBots darkMode={darkMode} />} />
                  <Route path="/dashboard/" element={<Dashboard darkMode={darkMode} />} />
                  <Route path="/dashboard/staff" element={<StaffDashboard darkMode={darkMode} />} />
                  <Route path="/auth/callback" element={<AuthCallback />} />
                  <Route path="/privacy" element={<PrivacyPolicy darkMode={darkMode} />} />
                  <Route path="/dashboard/admin" element={<AdminRoute><AdminDashboard darkMode={darkMode} /></AdminRoute>} />
                  <Route path="/terms" element={<TermsOfService darkMode={darkMode} />} />
                  <Route path="*" element={<NotFound darkMode={darkMode} />} />
                </Routes>
              </PageTransition>
            </main>
            <Footer darkMode={darkMode} />
            <Alert />
          </div>
        </Router>
      </AlertProvider>
  );
}

export default App;