import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

interface FooterProps {
  darkMode: boolean;
}

export const Footer: React.FC<FooterProps> = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChangelogClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/dashboard', { state: { initialTab: 'changelogs' } });
  };

  return (
    <footer className={`${darkMode ? 'bg-zinc-900 border-zinc-800' : 'bg-gray-100 border-gray-200'} border-t`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              NxPDev.dk
            </h3>
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Elevate your Discord experience with our universal bots
            </p>
          </div>

          <div className="space-y-4">
            <h3 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              Products
            </h3>
            <ul className={`space-y-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              <li>
                <Link to="https://discord.com/oauth2/authorize?client_id=1306549990461669407" className="hover:underline">
                  NxP Guard
                </Link>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              Resources
            </h3>
            <ul className={`space-y-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              <li>
                <Link to="/dashboard" className="hover:underline">
                  Dashboard
                </Link>
              </li>
              <li>
                <a 
                  href="https://discord.nxpdev.dk/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Support Server
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className="space-y-4">
            <h3 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              Legal
            </h3>
            <ul className={`space-y-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              <li>
                <Link to="/privacy" className="hover:underline">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="hover:underline">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className={`mt-8 pt-8 border-t ${darkMode ? 'border-zinc-800' : 'border-gray-200'}`}>
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              © {new Date().getFullYear()} NxPDev.dk. All rights reserved.
            </p>

            <div className="flex space-x-6">
              <a 
                href="https://discord.nxpdev.dk" 
                target="_blank" 
                rel="noopener noreferrer"
                className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
              >
                <i className="fab fa-discord"></i>
              </a>
              <a 
                href="https://github.com/NxPDevDK" 
                target="_blank" 
                rel="noopener noreferrer"
                className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
              >
                <i className="fab fa-github"></i>
              </a>
              <a 
                href="https://bsky.app/profile/nxpdev.dk" 
                target="_blank" 
                rel="noopener noreferrer"
                className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}
              >
                <i className="fab fa-bluesky"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}; 