import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface AnalyticsData {
  blockedUsers: number;
  blacklistedUsers: number;
  uptime: number;
}

export const Analytics: React.FC<{ darkMode: boolean }> = ({ darkMode }) => {
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);

  const formatUptime = (seconds: number): string => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      const response = await axios.get('https://api.nxpdev.dk/admin/analytics', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey
        }
      });

      setAnalytics(response.data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'}`}>
        <h3 className={`text-lg font-semibold mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          Blocked Users
        </h3>
        <p className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          {analytics?.blockedUsers || 0}
        </p>
      </div>

      <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'}`}>
        <h3 className={`text-lg font-semibold mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          Uptime
        </h3>
        <p className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          {formatUptime(analytics?.uptime || 0)}
        </p>
      </div>

      <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'}`}>
        <h3 className={`text-lg font-semibold mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          Blacklisted Users
        </h3>
        <p className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          {analytics?.blacklistedUsers || 0}
        </p>
      </div>
    </div>
  );
};