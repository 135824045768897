import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTransition } from './PageTransition';

interface NotFoundProps {
  darkMode: boolean;
}

export const NotFound: React.FC<NotFoundProps> = ({ darkMode }) => {
  const navigate = useNavigate();

  return (
    <PageTransition>
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} flex items-center justify-center p-4`}>
        <div className="text-center">
          <h1 className={`text-9xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'} mb-4`}>404</h1>
          <h2 className={`text-2xl font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-4`}>
            Page Not Found
          </h2>
          <p className={`text-lg ${darkMode ? 'text-gray-400' : 'text-gray-600'} mb-8`}>
            The page you're looking for doesn't exist or has been moved.
          </p>
          <div className="space-x-4">
            <button
              onClick={() => navigate('/')}
              className={`px-6 py-2 rounded-lg ${
                darkMode 
                  ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                  : 'bg-blue-500 hover:bg-blue-600 text-white'
              } transition-colors duration-200`}
            >
              Go Home
            </button>
            <button
              onClick={() => navigate(-1)}
              className={`px-6 py-2 rounded-lg ${
                darkMode
                  ? 'bg-zinc-800 hover:bg-zinc-700 text-white'
                  : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
              } transition-colors duration-200`}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}; 