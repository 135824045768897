import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Changelog } from '../../types/Changelog'; // Adjust the path as necessary

interface ChangelogEditorProps {
  darkMode: boolean;
  changelog?: Changelog | null;
  onSave: () => void;
  onCancel: () => void;
}

export const ChangelogEditor: React.FC<ChangelogEditorProps> = ({ 
  darkMode, 
  changelog, 
  onSave, 
  onCancel 
}) => {
  const [formData, setFormData] = useState<Changelog>(changelog || {
    version: '',
    title: '',
    description: '',
    type: 'FEATURE',
    created_at: new Date().toISOString()
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        throw new Error('Missing credentials');
      }

      let response;
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      };
      
      if (changelog?.id) {
        // Update existing changelog
        console.log('Updating changelog:', changelog.id);
        response = await axios.put(
          `https://api.nxpdev.dk/admin/changelog/${changelog.id}`,
          formData,
          config
        );
      } else {
        // Create new changelog
        console.log('Creating new changelog');
        response = await axios.post(
          'https://api.nxpdev.dk/admin/changelog',
          formData,
          config
        );
      }

      console.log('Response:', response);

      if (response.status === 200 || response.status === 201) {
        onSave();
      }
    } catch (error: any) {
      console.error('Error saving changelog:', error);
      setError(
        error.response?.data?.error || 
        error.message || 
        'Failed to save changelog. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          {changelog ? 'Edit Changelog' : 'Create Changelog'}
        </h2>
        <button
          onClick={onCancel}
          className={`px-4 py-2 rounded-lg ${
            darkMode 
              ? 'bg-zinc-700 hover:bg-zinc-600' 
              : 'bg-gray-200 hover:bg-gray-300'
          } transition-colors`}
        >
          Cancel
        </button>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Version
          </label>
          <input
            type="text"
            value={formData.version}
            onChange={(e) => setFormData(prev => ({ ...prev, version: e.target.value }))}
            className={`w-full p-2 rounded border ${
              darkMode 
                ? 'bg-zinc-700 border-zinc-600 text-white' 
                : 'bg-white border-gray-300'
            }`}
            required
          />
        </div>

        <div>
          <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Title
          </label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
            className={`w-full p-2 rounded border ${
              darkMode 
                ? 'bg-zinc-700 border-zinc-600 text-white' 
                : 'bg-white border-gray-300'
            }`}
            required
          />
        </div>

        <div>
          <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Type
          </label>
          <select
            value={formData.type}
            onChange={(e) => setFormData(prev => ({ 
              ...prev, 
              type: e.target.value as Changelog['type']
            }))}
            className={`w-full p-2 rounded border ${
              darkMode 
                ? 'bg-zinc-700 border-zinc-600 text-white' 
                : 'bg-white border-gray-300'
            }`}
            required
          >
            <option value="FEATURE">Feature</option>
            <option value="BUGFIX">Bug Fix</option>
            <option value="IMPROVEMENT">Improvement</option>
            <option value="BREAKING">Breaking Change</option>
          </select>
        </div>

        <div>
          <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Description
          </label>
          {/* Add styling toolbar here */}
          <textarea
            ref={textareaRef}
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            className={`w-full p-4 rounded border font-mono ${
              darkMode 
                ? 'bg-zinc-700 border-zinc-600 text-white' 
                : 'bg-white border-gray-300'
            }`}
            rows={15}
            required
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`px-6 py-2 rounded ${
              darkMode 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {loading ? (
              <>
                <i className="fas fa-spinner fa-spin mr-2"></i>
                Saving...
              </>
            ) : (
              'Save Changelog'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};