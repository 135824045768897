import React from 'react';
import { Button } from 'flowbite-react';
import { PageTransition } from './PageTransition';

interface LandingProps {
  darkMode: boolean;
}

export const Landing: React.FC<LandingProps> = ({ darkMode }) => {
  return (
    <PageTransition>
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'}`}>
        <section className="relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-12">
            <div className="text-center">
              <h1 className={`text-5xl md:text-6xl font-extrabold tracking-tight ${
                darkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Elevate Your Discord Experience
              </h1>
              <p className={`mt-6 text-xl ${
                darkMode ? 'text-gray-300' : 'text-gray-600'
              } max-w-3xl mx-auto`}>
                Powerful Discord bots designed to enhance your server with moderation, music, and more.
                Join thousands of servers already using our solutions.
              </p>
              <div className="mt-10 flex justify-center gap-4">
                <Button
                  size="xl"
                  color="blue"
                  href="/bots"
                  className="font-semibold"
                >
                  Explore Our Bots
                </Button>
                <Button
                  size="xl"
                  color={darkMode ? 'gray' : 'light'}
                  href="https://discord.nxpdev.dk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold"
                >
                  Join Our Community
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className={`py-20 ${darkMode ? 'bg-zinc-800' : 'bg-white'}`}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                Why Choose Our Bots?
              </h2>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <div key={index} className={`p-6 rounded-lg ${
                  darkMode ? 'bg-zinc-700' : 'bg-gray-50'
                }`}>
                  <div className={`w-12 h-12 flex items-center justify-center rounded-lg ${
                    darkMode ? 'bg-blue-600' : 'bg-blue-500'
                  } text-white mb-4`}>
                    <i className={`${feature.icon} text-xl`}></i>
                  </div>
                  <h3 className={`text-xl font-semibold mb-2 ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {feature.title}
                  </h3>
                  <p className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid md:grid-cols-3 gap-8 text-center">
              {stats.map((stat, index) => (
                <div key={index}>
                  <div className={`text-4xl font-bold mb-2 ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {stat.value}
                  </div>
                  <div className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
                    {stat.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={`py-20 ${darkMode ? 'bg-zinc-800' : 'bg-blue-50'}`}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className={`text-3xl font-bold mb-8 ${
              darkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Ready to Transform Your Discord Server?
            </h2>
            <Button
              size="xl"
              color="blue"
              href="/bots"
              className="font-semibold"
            >
              Get Started Now
            </Button>
          </div>
        </section>
      </div>
    </PageTransition>
  );
};

const features = [
  {
    icon: 'fas fa-shield-alt',
    title: 'Advanced Moderation',
    description: 'Keep your server safe with powerful moderation tools and automated systems.'
  },
  {
    icon: 'fas fa-robot',
    title: 'Premium Features',
    description: 'Access exclusive features and enhanced capabilities with our premium bot options.'
  },
  {
    icon: 'fas fa-cogs',
    title: 'Easy Configuration',
    description: 'Simple setup process and intuitive commands for all our bots.'
  },
  {
    icon: 'fas fa-chart-line',
    title: 'Bot Statistics',
    description: 'View detailed statistics and reports about our bot activity.'
  },
  {
    icon: 'fas fa-clock',
    title: '24/7 Uptime',
    description: 'Our bots are hosted on reliable infrastructure for maximum availability.'
  },
  {
    icon: 'fas fa-headset',
    title: 'Premium Support',
    description: 'Dedicated support team ready to help you with any questions.'
  }
];

const stats = [
  {
    value: '10+',
    label: 'Active Servers'
  },
  {
    value: 'N/A',
    label: 'Happy Users'
  },
  {
    value: '98%',
    label: 'Uptime'
  }
];

export default Landing; 