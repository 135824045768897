import React, { createContext, useState, useCallback, useContext, useRef } from 'react';

type AlertType = 'success' | 'error' | 'info' | 'warning';

interface Alert {
    id: number;
    message: string;
    type: AlertType;
}

interface AlertContextType {
    alerts: Alert[];
    showAlert: (message: string, type: AlertType) => void;
}

export const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    // Opret audio element når komponenten mountes
    React.useEffect(() => {
        audioRef.current = new Audio('/notification.mp3');
    }, []);

    const showAlert = useCallback((message: string, type: AlertType) => {
        const id = Date.now();
        setAlerts(prev => [...prev, { id, message, type }]);

        // Afspil lyden
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // Reset lyden hvis den allerede spiller
            audioRef.current.play().catch(e => console.error('Error playing sound:', e));
        }

        setTimeout(() => {
            setAlerts(prev => prev.filter(alert => alert.id !== id));
        }, 3000);
    }, []);

    return (
        <AlertContext.Provider value={{ alerts, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
}; 