import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MarkdownToolbar } from '../common/MarkdownToolbar';

interface PrivacyPolicyManagerProps {
  darkMode: boolean;
}

interface PrivacySection {
  id: number;
  title: string;
  content: string;
  order: number;
}

export const PrivacyPolicyManager: React.FC<PrivacyPolicyManagerProps> = ({ darkMode }) => {
  const [sections, setSections] = useState<PrivacySection[]>([]);
  const [editingSection, setEditingSection] = useState<PrivacySection | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = async () => {
    try {
      const response = await axios.get('https://api.nxpdev.dk/privacy/sections');
      setSections(response.data);
    } catch (error) {
      setError('Failed to fetch privacy sections');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (section: PrivacySection) => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        alert('You must be logged in to perform this action');
        throw new Error('Missing authentication credentials');
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      };

      const requestData = {
        title: section.title,
        content: section.content,
        order: section.order
      };

      let response;
      if (section.id) {
        response = await axios.put(
          `https://api.nxpdev.dk/privacy/sections/${section.id}`,
          requestData,
          config
        );
      } else {
        response = await axios.post(
          'https://api.nxpdev.dk/privacy/sections',
          requestData,
          config
        );
      }

      await fetchSections();
      setEditingSection(null);

    } catch (error: any) {
      console.error('Detailed error:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to save section';
      alert(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this section?')) {
      try {
        await axios.delete(`https://api.nxpdev.dk/privacy/sections/${id}`);
        fetchSections();
      } catch (error) {
        setError('Failed to delete section');
        console.error('Error:', error);
      }
    }
  };

  const SectionEditor: React.FC<{ section?: PrivacySection | null }> = ({ section }) => {
    const [formData, setFormData] = useState<PrivacySection>({
      id: section?.id || 0,
      title: section?.title || '',
      content: section?.content || '',
      order: section?.order || sections.length
    });

    return (
      <div className={`p-6 rounded-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-lg`}>
        <h3 className={`text-xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          {section ? 'Edit Section' : 'Add New Section'}
        </h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSave(formData);
        }}>
          <div className="space-y-4">
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Title
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className={`w-full p-2 rounded border ${
                  darkMode 
                    ? 'bg-zinc-700 border-zinc-600 text-white' 
                    : 'bg-white border-gray-300'
                }`}
                required
              />
            </div>
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Content
              </label>
              <MarkdownToolbar 
                textAreaId="privacy-content"
                darkMode={darkMode} 
              />
              <textarea
                id="privacy-content"
                value={formData.content}
                onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                rows={6}
                className={`w-full p-2 rounded-b border-x border-b ${
                  darkMode 
                    ? 'bg-zinc-700 border-zinc-600 text-white' 
                    : 'bg-white border-gray-300'
                }`}
                required
              />
            </div>
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Order
              </label>
              <input
                type="number"
                value={formData.order}
                onChange={(e) => setFormData({ ...formData, order: parseInt(e.target.value) })}
                className={`w-full p-2 rounded border ${
                  darkMode 
                    ? 'bg-zinc-700 border-zinc-600 text-white' 
                    : 'bg-white border-gray-300'
                }`}
                required
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setEditingSection(null)}
                className={`px-4 py-2 rounded ${
                  darkMode 
                    ? 'bg-zinc-700 hover:bg-zinc-600' 
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          Privacy Policy Manager
        </h2>
        <button
          onClick={() => setEditingSection({
            id: 0,
            title: '',
            content: '',
            order: sections.length
          })}
          className={`px-4 py-2 rounded-lg ${
            darkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white transition-colors`}
        >
          <i className="fas fa-plus mr-2"></i>
          Add Section
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      {editingSection !== null ? (
        <SectionEditor section={editingSection} />
      ) : (
        <div className="space-y-4">
          {sections.map((section) => (
            <div
              key={section.id}
              className={`p-6 rounded-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-lg`}
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setEditingSection(section)}
                    className={`p-2 rounded ${
                      darkMode ? 'hover:bg-zinc-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    onClick={() => handleDelete(section.id)}
                    className={`p-2 rounded ${
                      darkMode ? 'hover:bg-zinc-700' : 'hover:bg-gray-100'
                    } text-red-500`}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <ReactMarkdown 
                className="prose prose-sm max-w-none dark:prose-invert"
                remarkPlugins={[remarkGfm]}
              >
                {section.content}
              </ReactMarkdown>
              <div className={`mt-2 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Order: {section.order}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}; 