import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Analytics } from './admin/Analytics';
import { ChangelogManager } from './admin/ChangelogManager';
import { PrivacyPolicyManager } from './admin/PrivacyPolicyManager';
import { TermsManager } from './admin/TermsManager';
import axios from 'axios';
import { useAlert } from '../contexts/AlertContext';
import { BlacklistManager } from './admin/BlacklistManager';
import { AnimatePresence, motion } from 'framer-motion';

interface AdminDashboardProps {
  darkMode: boolean;
}

interface UnblacklistRequest {
  id: string;
  user_id: string;
  reason: string;
  status: string;
}

export const AdminDashboard: React.FC<AdminDashboardProps> = ({ darkMode }) => {
  const [currentPage, setCurrentPage] = useState<'analytics' | 'changelogs' | 'privacy' | 'terms' | 'blacklist' | 'unblacklist'>('analytics');
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [unblacklistRequests, setUnblacklistRequests] = useState<UnblacklistRequest[]>([]);
  const [usernames, setUsernames] = useState<{ [key: string]: string }>({});
  const [adminDiscordId, setAdminDiscordId] = useState<string>('');

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const token = localStorage.getItem('discord_token');
        const apiKey = process.env.REACT_APP_API_KEY;

        if (!token || !apiKey) {
          throw new Error('Missing credentials');
        }

        const response = await axios.get('https://api.nxpdev.dk/auth/admin', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey
          }
        });

        if (!response.data.isAdmin) {
          navigate('/dashboard');
          showAlert('Unauthorized access', 'error');
        }
      } catch (error) {
        navigate('/dashboard');
        showAlert('Unauthorized access', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminAccess();
  }, [navigate, showAlert]);

  useEffect(() => {
    const fetchAdminDiscordId = () => {
      const discordId = localStorage.getItem('discord_user_id');
      if (discordId) {
        setAdminDiscordId(discordId);
      }
    };

    fetchAdminDiscordId();
  }, []);

  const fetchUnblacklistRequests = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        showAlert('Missing credentials', 'error');
        return;
      }

      const response = await axios.get<UnblacklistRequest[]>('https://api.nxpdev.dk/api/unblacklist/requests', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
        },
      });

      setUnblacklistRequests(response.data);
      fetchUsernames(response.data.map(req => req.user_id));
    } catch (error) {
      showAlert('Failed to fetch unblacklist requests', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUnblacklistRequests();
  }, []);

  const fetchUsernames = async (userIds: string[]) => {
    try {
      const responses = await Promise.all(userIds.map(userId =>
        axios.get(`https://api.nxpdev.dk/api/user/info?user=${userId}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY || ''
          }
        })
      ));

      const newUsernames = responses.reduce((acc, response, index) => {
        if (response.data.username) {
          acc[userIds[index]] = response.data.username;
        }
        return acc;
      }, {} as { [key: string]: string });

      setUsernames(prev => ({ ...prev, ...newUsernames }));
    } catch (error) {}
  };

  const handleUnblacklistResponse = async (requestId: string, approve: boolean) => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey || !adminDiscordId) {
        console.error('Missing credentials or Discord ID');
        return;
      }

      await axios.post('https://api.nxpdev.dk/api/unblacklist/respond', {
        requestId,
        approve,
        discordid: adminDiscordId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
        },
      });

      fetchUnblacklistRequests();
    } catch (error) {
      console.error('Error submitting response:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
      }
      showAlert('Failed to submit response', 'error');
    }
  };

  const pendingRequests = unblacklistRequests.filter(request => request.status === 'pending');
  const respondedRequests = unblacklistRequests.filter(request => request.status !== 'pending');

  if (isLoading) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} flex items-center justify-center`}>
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const copyToClipboard = (text: string, displayName: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showAlert(`Copied ${displayName}'s Discord ID!`, 'success');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} py-12`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex space-x-4 mb-8">
          <button
            onClick={() => setCurrentPage('analytics')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'analytics' 
                ? 'bg-blue-500 text-white' 
                : darkMode 
                  ? 'bg-zinc-800 text-gray-300' 
                  : 'bg-white text-gray-700'
            }`}
          >
            Analytics
          </button>
          <button
            onClick={() => setCurrentPage('changelogs')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'changelogs' 
                ? 'bg-blue-500 text-white' 
                : darkMode 
                  ? 'bg-zinc-800 text-gray-300' 
                  : 'bg-white text-gray-700'
            }`}
          >
            Changelogs
          </button>
          <button
            onClick={() => setCurrentPage('privacy')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'privacy' 
                ? 'bg-blue-500 text-white' 
                : darkMode 
                  ? 'bg-zinc-800 text-gray-300' 
                  : 'bg-white text-gray-700'
            }`}
          >
            Privacy Policy
          </button>
          <button
            onClick={() => setCurrentPage('terms')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'terms' 
                ? 'bg-blue-500 text-white' 
                : darkMode 
                  ? 'bg-zinc-800 text-gray-300' 
                  : 'bg-white text-gray-700'
            }`}
          >
            Terms of Service
          </button>
          <button
            onClick={() => setCurrentPage('blacklist')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'blacklist' ? 'bg-blue-500 text-white' : darkMode ? 'bg-zinc-800 text-gray-300' : 'bg-white text-gray-700'
            }`}
          >
            Blacklist
          </button>
          <button
            onClick={() => setCurrentPage('unblacklist')}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 'unblacklist' ? 'bg-blue-500 text-white' : darkMode ? 'bg-zinc-800 text-gray-300' : 'bg-white text-gray-700'
            }`}
          >
            Unblacklist Requests
          </button>
        </div>

        <div className={`p-6 rounded-lg ${darkMode ? 'bg-zinc-800' : 'bg-white'} shadow-xl`}>
          {currentPage === 'analytics' && <Analytics darkMode={darkMode} />}
          {currentPage === 'changelogs' && <ChangelogManager darkMode={darkMode} />}
          {currentPage === 'privacy' && <PrivacyPolicyManager darkMode={darkMode} />}
          {currentPage === 'terms' && <TermsManager darkMode={darkMode} />}
          {currentPage === 'blacklist' && <BlacklistManager darkMode={darkMode} />}
          {currentPage === 'unblacklist' && (
            <>
              <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Pending Unblacklist Requests</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
                <AnimatePresence mode="wait">
                  {pendingRequests.map((request, index) => (
                    <motion.div
                      key={request.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ 
                        duration: 0.3,
                        delay: index * 0.1,
                        ease: "easeOut"
                      }}
                      className={`p-6 rounded-lg ${
                        darkMode ? 'bg-zinc-800/50 hover:bg-zinc-800' : 'bg-white hover:bg-gray-50'
                      } shadow-lg transition-all duration-200`}
                    >
                      <div className="flex items-start gap-4 mb-4">
                        <motion.div 
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ delay: index * 0.1 + 0.2 }}
                          className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center flex-shrink-0"
                        >
                          <span className="text-blue-500 text-lg font-semibold">
                            {(usernames[request.user_id] || request.user_id).charAt(0).toUpperCase()}
                          </span>
                        </motion.div>
                        <div className="min-w-0 flex-1">
                          <button
                            onClick={() => copyToClipboard(request.user_id, usernames[request.user_id] || request.user_id)}
                            className="hover:text-blue-400 transition-colors cursor-pointer relative group text-left"
                          >
                            <h3 className={`text-xl font-semibold truncate ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                              {usernames[request.user_id] || request.user_id}
                            </h3>
                            <span className="tooltip">Copy Discord ID</span>
                          </button>
                          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} truncate`}>
                            Discord ID: {request.user_id}
                          </p>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none text-sm`}>
                          <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} truncate`}>
                            <strong>Reason:</strong> {request.reason}
                          </p>
                        </div>
                        <div className="mt-4 flex justify-end">
                          <button onClick={() => handleUnblacklistResponse(request.id, true)} className="mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">Approve</button>
                          <button onClick={() => handleUnblacklistResponse(request.id, false)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Deny</button>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>

              <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Responded Unblacklist Requests</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <AnimatePresence mode="wait">
                  {respondedRequests.map((request, index) => (
                    <motion.div
                      key={request.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ 
                        duration: 0.3,
                        delay: index * 0.1,
                        ease: "easeOut"
                      }}
                      className={`p-6 rounded-lg ${
                        darkMode ? 'bg-zinc-800/50 hover:bg-zinc-800' : 'bg-white hover:bg-gray-50'
                      } shadow-lg transition-all duration-200`}
                    >
                      <div className="flex items-start gap-4 mb-4">
                        <motion.div 
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ delay: index * 0.1 + 0.2 }}
                          className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center flex-shrink-0"
                        >
                          <span className="text-blue-500 text-lg font-semibold">
                            {(usernames[request.user_id] || request.user_id).charAt(0).toUpperCase()}
                          </span>
                        </motion.div>
                        <div className="min-w-0 flex-1">
                          <button
                            onClick={() => copyToClipboard(request.user_id, usernames[request.user_id] || request.user_id)}
                            className="hover:text-blue-400 transition-colors cursor-pointer relative group text-left"
                          >
                            <h3 className={`text-xl font-semibold truncate ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                              {usernames[request.user_id] || request.user_id}
                            </h3>
                            <span className="tooltip">Copy Discord ID</span>
                          </button>
                          <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} truncate`}>
                            Discord ID: {request.user_id}
                          </p>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none text-sm`}>
                          <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} truncate`}>
                            <strong>Reason:</strong> {request.reason}
                          </p>
                        </div>
                        <div className="mt-4">
                          <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                            Status: {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}; 