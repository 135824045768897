import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { LoginButton } from './LoginButton';
import { useNavigate } from 'react-router-dom';
import { Changelogs } from './Changelogs';
import { motion, AnimatePresence } from 'framer-motion';
import 'odometer/themes/odometer-theme-minimal.css';
import Odometer from 'react-odometerjs';
import { useAlert } from '../contexts/AlertContext';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import UnblacklistModal from './UnblacklistModal';
import { Pagination } from 'flowbite-react';

interface DashboardProps {
  darkMode: boolean;
}

interface Report {
  id: string;
  date: Date;
  reportedUser: string;
  reason: string;
  status: string;
  handler: string;
}

interface BlacklistEntry {
  userid: string;
  reason: string;
  active: boolean;
  staffid: string;
  date: string;
}

interface BlockedEntry {
  userid: string;
  reason: string;
  staff_id: string;
}

interface UserStats {
  reports: Report[];
  reportCount: number;
  blacklist: BlacklistEntry[];
  blocked: BlockedEntry[];
  avatar: string;
  username: string;
  discriminator: string;
}

type TabType = 'dashboard' | 'changelogs';

interface Changelog {
    id: number;
    version: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    type: string;
    author: {
        id: string;
        username: string;
        avatar: string;
    };
}

interface UnblacklistRequest {
  id: string;
  user_id: string;
  reason: string;
  status: string;
}

const getStatusStyle = (status: string) => {
    switch (status.toLowerCase()) {
        case 'pending':
            return 'bg-yellow-500/20 text-yellow-400';
        case 'accepted':
            return 'bg-green-500/20 text-green-400';
        case 'rejected':
            return 'bg-red-500/20 text-red-400';
        default:
            return 'bg-gray-500/20 text-gray-400';
    }
};

export const Dashboard: React.FC<DashboardProps> = ({ darkMode }) => {
  const [currentTab, setCurrentTab] = useState<TabType>('dashboard');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState<UserStats>({
    reports: [],
    reportCount: 0,
    blacklist: [],
    blocked: [],
    avatar: '',
    username: '',
    discriminator: ''
  });

  const [usernames, setUsernames] = useState<{ [key: string]: string }>({});

  const { showAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [changelogs, setChangelogs] = useState<Changelog[]>([]);

  const [unblacklistReason, setUnblacklistReason] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [unblacklistRequests, setUnblacklistRequests] = useState<UnblacklistRequest[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 3;

  const fetchUsername = async (userId: string) => {
    try {
        const response = await axios.get(`https://api.nxpdev.dk/api/user/info?user=${userId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY || localStorage.getItem('apiKey') || ''
            }
        });
        
        if (response.data && response.data.username) {
            setUsernames(prev => ({
                ...prev,
                [userId]: response.data.username
            }));
        }
    } catch (error) {}
  };

  const fetchChangelogs = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get('https://api.nxpdev.dk/changelogs', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY || localStorage.getItem('apiKey') || ''
            }
        });
        
        if (response.data) {
            setChangelogs(response.data);
        }
    } catch (error) {
        showAlert('Failed to fetch changelogs', 'error');
        console.error('Error fetching changelogs:', error);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChangelogs();
  }, []);

  useEffect(() => {
    userStats.reports.forEach(report => {
        if (!usernames[report.reportedUser]) {
            fetchUsername(report.reportedUser);
        }
        if (report.handler && !usernames[report.handler]) {
            fetchUsername(report.handler);
        }
    });
  }, [userStats.reports]);

  const copyToClipboard = (text: string, displayName: string) => {
    navigator.clipboard.writeText(text);
    showAlert(`Copied ${displayName}'s Discord ID!`, 'success');
  };

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    const storedUserId = localStorage.getItem('discord_user_id');
    
    if (authStatus === 'true' && storedUserId) {
      setIsAuthenticated(true);
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    const fetchUserStats = async () => {
      if (!isAuthenticated || !userId) return;

      try {
        const token = localStorage.getItem('discord_token');
        const apiKey = process.env.REACT_APP_API_KEY;
        
        if (!apiKey) {
          console.error('API key is not defined');
          return;
        }

        const response = await axios.get<UserStats>(
          `https://api.nxpdev.dk/api/user/stats?user=${userId}`,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'x-api-key': apiKey
            }
          }
        );
        setUserStats(response.data);
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    fetchUserStats();
  }, [isAuthenticated, userId]);

  const isDashboardTab = (tab: TabType): boolean => {
    return tab === 'dashboard';
  };

  const getTypeColor = (type: string) => {
    switch (type) {
      case 'FEATURE': return 'bg-green-500/20 text-green-400';
      case 'BUGFIX': return 'bg-red-500/20 text-red-400';
      case 'IMPROVEMENT': return 'bg-blue-500/20 text-blue-400';
      case 'OTHER': return 'bg-yellow-500/20 text-yellow-400';
      default: return 'bg-gray-500/20 text-gray-400';
    }
  };

  const handleUnblacklistRequest = async () => {
    try {
      const response = await axios.post('https://api.nxpdev.dk/api/unblacklist/request', {
        userId,
        reason: unblacklistReason,
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY || '',
        },
      });
      showAlert('Unblacklist request submitted', 'success');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'User is not currently blacklisted') {
          showAlert('You are not currently blacklisted.', 'info');
        } else {
          showAlert('Failed to submit unblacklist request', 'error');
        }
      } else {
        showAlert('Failed to submit unblacklist request', 'error');
      }
    }
  };

  const handleUnblacklistSubmit = async (reason: string) => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        showAlert('Missing credentials', 'error');
        return;
      }

      await axios.post('https://api.nxpdev.dk/api/unblacklist/request', {
        userId,
        reason,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
        },
      });

      showAlert('Unblacklist request submitted', 'success');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'User is not currently blacklisted') {
          showAlert('You are not currently blacklisted.', 'info');
        } else {
          showAlert('Failed to submit unblacklist request', 'error');
        }
      } else {
        showAlert('Failed to submit unblacklist request', 'error');
      }
    }
  };

  const fetchUnblacklistRequests = async () => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        showAlert('Missing credentials', 'error');
        return;
      }

      const response = await axios.get<UnblacklistRequest[]>('https://api.nxpdev.dk/api/unblacklist/requests', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey,
        },
      });

      setUnblacklistRequests(response.data);
    } catch (error) {
      showAlert('Failed to fetch unblacklist requests', 'error');
    }
  };

  useEffect(() => {
    fetchUnblacklistRequests();
  }, []);

  const pendingRequest = unblacklistRequests.find(request => {
    return request.user_id === userId && request.status === 'pending';
  });

  const isBlacklisted = userStats.blacklist.some(entry => entry.active);

  const totalPages = Math.ceil(changelogs.length / ITEMS_PER_PAGE);
  const currentItems = changelogs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  if (!isAuthenticated && isDashboardTab(currentTab)) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'}`}>
        <div className="p-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex space-x-4 mb-8">
              <button
                onClick={() => setCurrentTab('dashboard')}
                className={`px-4 py-2 rounded-lg ${
                  currentTab === 'dashboard' 
                    ? 'bg-zinc-500 text-white' 
                    : darkMode 
                      ? 'bg-zinc-800 text-gray-300' 
                      : 'bg-white text-gray-700'
                }`}
              >
                Dashboard
              </button>
              <button
                onClick={() => setCurrentTab('changelogs')}
                className={`px-4 py-2 rounded-lg ${
                  currentTab === 'changelogs' 
                    ? 'bg-zinc-500 text-white' 
                    : darkMode 
                      ? 'bg-zinc-800 text-gray-300' 
                      : 'bg-white text-gray-700'
                }`}
              >
                Changelogs
              </button>
            </div>

            <div className={`h-px ${darkMode ? 'bg-zinc-700' : 'bg-gray-200'} mb-8`}></div>

            <div className="text-center">
              <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
                Please login to view your dashboard
              </h2>
              <LoginButton darkMode={darkMode} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'}`}>
      <div className="p-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex space-x-4 mb-8">
            <button
              onClick={() => setCurrentTab('dashboard')}
              className={`px-4 py-2 rounded-lg ${
                currentTab === 'dashboard' 
                  ? 'bg-zinc-500 text-white' 
                  : darkMode 
                    ? 'bg-zinc-800 text-gray-300' 
                    : 'bg-white text-gray-700'
              }`}
            >
              Dashboard
            </button>
            <button
              onClick={() => setCurrentTab('changelogs')}
              className={`px-4 py-2 rounded-lg ${
                currentTab === 'changelogs' 
                  ? 'bg-zinc-500 text-white' 
                  : darkMode 
                    ? 'bg-zinc-800 text-gray-300' 
                    : 'bg-white text-gray-700'
              }`}
            >
              Changelogs
            </button>
          </div>

          <div className={`h-px ${darkMode ? 'bg-zinc-700' : 'bg-gray-200'} mb-8`}></div>

          <AnimatePresence mode="wait">
            <motion.div
              key={currentTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              {currentTab === 'dashboard' ? (
                <>
                  <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                      <div>
                        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>{userStats.username}#{userStats.discriminator}</h2>
                        <p className={`${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>Discord ID: {userId}</p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-4 mb-8">
                    <div className={`bg-${darkMode ? 'zinc-800' : 'gray-500'} p-4 rounded-lg text-center`}>
                      <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Reports</h3>
                      <div className="text-4xl font-bold text-blue-500">
                        <Odometer
                            value={userStats.reports.length}
                            format="d"
                            duration={1000}
                            theme="minimal"
                        />
                      </div>
                      <div className={`${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
                        <Odometer
                            value={userStats.reportCount}
                            format="d"
                            duration={1000}
                            theme="minimal"
                        /> Times Reported
                      </div>
                    </div>
                    <div className={`bg-${darkMode ? 'zinc-800' : 'gray-500'} p-4 rounded-lg text-center`}>
                      <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Blacklist Status</h3>
                      <motion.p
                        key={isBlacklisted.toString()}
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ type: "spring", stiffness: 100 }}
                        className={`text-xl font-bold ${
                          isBlacklisted ? 'text-red-500' : 'text-green-500'
                        }`}
                      >
                        {isBlacklisted ? 'Blacklisted' : 'Good boy'}
                      </motion.p>
                      <p className={`text-gray-400 ${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
                        {isBlacklisted
                          ? userStats.blacklist.find(entry => entry.active)?.reason
                          : 'No active restrictions'}
                      </p>

                      {isBlacklisted && (
                        pendingRequest ? (
                          <div className={`bg-${darkMode ? 'zinc-800' : 'gray-500'} p-4 rounded-lg text-center`}>
                            <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Pending Unblacklist Request</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>Reason: {pendingRequest.reason}</p>
                            <p className={`${darkMode ? 'text-yellow-400' : 'text-yellow-600'}`}>Status: {pendingRequest.status}</p>
                            <p className={`${darkMode ? 'text-yellow-400' : 'text-yellow-600'}`}>Your request is pending. Please wait for a response.</p>
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={() => setIsModalOpen(true)}
                              className={`mt-6 px-4 py-2 rounded ${darkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600'} text-white`}
                            >
                              Request Unblacklist
                            </button>
                          </div>
                        )
                      )}
                    </div>
                    <div className={`bg-${darkMode ? 'zinc-800' : 'gray-500'} p-4 rounded-lg text-center`}>
                      <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Blocked Status</h3>
                      <motion.p 
                        key={userStats.blocked.length > 0 ? 'blocked' : 'not-blocked'}
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ type: "spring", stiffness: 100 }}
                        className={`text-xl font-bold ${
                            userStats.blocked.length > 0 
                                ? 'text-red-500' 
                                : 'text-green-500'
                        }`}
                      >
                        {userStats.blocked.length > 0 ? 'Blocked' : 'Not Blocked'}
                      </motion.p>
                      <p className={`text-gray-400 ${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
                        {userStats.blocked.length > 0 
                            ? userStats.blocked[0]?.reason 
                            : 'No active restrictions'}
                      </p>
                    </div>
                  </div>

                  <div className={`bg-${darkMode ? 'zinc-800' : 'gray-500'} p-4 rounded-lg overflow-x-auto`}>
                    <h3 className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-900'} mb-4`}>Recent Reports</h3>
                    <div className="min-w-[1000px]">
                      <div className={`grid grid-cols-6 gap-4 ${darkMode ? 'text-gray-400 border-zinc-700' : 'text-gray-900 border-gray-200'} p-2 border-b`}>
                        <span className="font-medium text-center">REPORT ID</span>
                        <span className="font-medium text-center">DATE</span>
                        <span className="font-medium text-center">USER</span>
                        <span className="font-medium text-center">REASON</span>
                        <span className="font-medium text-center">STATUS</span>
                        <span className="font-medium text-center">STAFF</span>
                      </div>
                      {userStats.reports.map((report) => (
                        <div key={report.id} className="grid grid-cols-6 gap-4 text-gray-400 p-2 hover:bg-zinc-700">
                          <span className="text-center">#{report.id}</span>
                          <span className="text-center">
                            {new Date(report.date).toLocaleString()}
                          </span>
                          <span className="text-center">
                            <button
                              onClick={() => copyToClipboard(
                                report.reportedUser, 
                                usernames[report.reportedUser]
                              )}
                              className="hover:text-blue-400 transition-colors cursor-pointer relative group"
                            >
                              <span className="relative">
                                {usernames[report.reportedUser] || 'Loading...'}
                                <span className={`absolute left-1/2 -translate-x-1/2 -top-10 
                                  scale-0 transition-all rounded bg-${darkMode ? 'zinc-800' : 'gray-500'} p-2 text-xs ${darkMode ? 'text-white' : 'text-gray-900'} group-hover:scale-100
                                  whitespace-nowrap before:content-[''] before:absolute before:left-1/2 before:-translate-x-1/2
                                  before:top-full before:border-4 before:border-transparent before:border-t-${darkMode ? 'zinc-800' : 'gray-500'}`}
                                >
                                  Copy Discord ID
                                </span>
                              </span>
                            </button>
                          </span>
                          <span className="text-center">{report.reason}</span>
                          <span className="text-center">
                            <span className={`px-3 py-1 rounded-full text-sm font-medium inline-block ${getStatusStyle(report.status)}`}>
                              {report.status.charAt(0).toUpperCase() + report.status.slice(1)}
                            </span>
                          </span>
                          <span className="text-center">
                            {report.handler ? (
                              <button
                                onClick={() => copyToClipboard(
                                  report.handler, 
                                  usernames[report.handler]
                                )}
                                className="hover:text-blue-400 transition-colors cursor-pointer relative group"
                              >
                                <span className="relative">
                                  {usernames[report.handler] || 'Loading...'}
                                  <span className={`absolute left-1/2 -translate-x-1/2 -top-10 
                                    scale-0 transition-all rounded bg-${darkMode ? 'zinc-800' : 'gray-500'} p-2 text-xs ${darkMode ? 'text-white' : 'text-gray-900'} group-hover:scale-100
                                    whitespace-nowrap before:content-[''] before:absolute before:left-1/2 before:-translate-x-1/2
                                    before:top-full before:border-4 before:border-transparent before:border-t-${darkMode ? 'zinc-800' : 'gray-500'}`}
                                  >
                                    Copy Staff ID
                                  </span>
                                </span>
                              </button>
                            ) : (
                              <span>N/A</span>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="p-8">
                  <div className="flex justify-between items-center mb-8">
                    <h2 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                      Changelogs
                    </h2>
                    <button
                      onClick={fetchChangelogs}
                      className={`px-4 py-2 rounded-lg ${
                        darkMode ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-blue-500 hover:bg-blue-600 text-white'
                      }`}
                    >
                      Refresh
                    </button>
                  </div>

                  {isLoading ? (
                    <p className={`${darkMode ? 'text-white' : 'text-gray-900'}`}>Loading...</p>
                  ) : (
                    <div className="space-y-6">
                      {currentItems.map((changelog: Changelog) => (
                        <motion.div
                          key={changelog.id}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3 }}
                          className={`p-6 rounded-lg shadow-md ${
                            darkMode ? 'bg-zinc-800' : 'bg-white'
                          }`}
                        >
                          <div className="flex justify-between items-center mb-4">
                            <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                              {changelog.title}
                            </h3>
                            <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              {new Date(changelog.created_at).toLocaleDateString()}
                            </span>
                          </div>
                          <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none mb-4`}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                              {changelog.description}
                            </ReactMarkdown>
                          </div>
                          <div className="flex items-center space-x-2 mb-4">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                              ${changelog.type === 'FEATURE' 
                                ? 'bg-green-100 text-green-800' 
                                : changelog.type === 'BUGFIX'
                                  ? 'bg-red-100 text-red-800'
                                  : 'bg-blue-100 text-blue-800'
                              }`}>
                              {changelog.type}
                            </span>
                            <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              Version {changelog.version}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <img src={changelog.author.avatar} alt={changelog.author.username} className="w-8 h-8 rounded-full" />
                            <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              Published by {changelog.author.username}
                            </span>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  )}
                  <div className="flex justify-end mt-8">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <UnblacklistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleUnblacklistSubmit}
        darkMode={darkMode}
        />
    </div>
  );
};

export default Dashboard;