import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import ReactPlayer from 'react-player';
import { useAlert } from '../contexts/AlertContext';

interface StaffDashboardProps {
  darkMode: boolean;
}

interface Report {
  id: string;
  reporter_id: string;
  reported_id: string;
  reason: string;
  status: string;
  timestamp: Date;
  message_id: string | null;
  evidence_url: string | null;
  handler: string | null;
}

export const StaffDashboard: React.FC<StaffDashboardProps> = ({ darkMode }) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const { showAlert } = useAlert();
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState<string | null>(null);
  const navigate = useNavigate();
  const playerRef = useRef<ReactPlayer>(null);
  const [volume, setVolume] = useState(0.8);

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem('discord_token');
        const apiKey = process.env.REACT_APP_API_KEY;
        
        if (!token || !apiKey) {
          showAlert('Unauthorized access', 'error');
          navigate('/dashboard');
          return;
        }

        const staffResponse = await fetch('https://api.nxpdev.dk/auth/staff', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey
          }
        });

        const staffData = await staffResponse.json();

        if (!staffResponse.ok || !staffData.isStaff) {
          showAlert('Unauthorized access', 'error');
          navigate('/dashboard');
          return;
        }

        const reportsResponse = await fetch('https://api.nxpdev.dk/reports/all', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey
          }
        });

        const reportsData = await reportsResponse.json();

        if (!reportsResponse.ok) {
          throw new Error(reportsData.details || reportsData.error || 'Failed to fetch reports');
        }

        setReports(reportsData.reports || []);
      } catch (error) {
        console.error('Error:', error);
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, [navigate]);

  if (loading) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} p-8 flex items-center justify-center`}>
        <div className="text-center">
          <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
            Loading...
          </h2>
        </div>
      </div>
    );
  }

  const openEvidenceModal = (url: string) => {
    setSelectedEvidence(url);
    setIsModalOpen(true);
  };

  const isVideo = (url: string) => {
    const cleanUrl = url.split('?')[0];
    const result = /\.(mp4|webm|ogg)$/i.test(cleanUrl);
    return result;
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} py-12`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {loading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        )}

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}

        {!loading && !error && reports.length === 0 && (
          <div className="text-center py-8">
            <div className={`rounded-lg p-6 ${darkMode ? 'bg-zinc-800' : 'bg-gray-50'}`}>
              <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>No reports found</p>
            </div>
          </div>
        )}

        {!loading && !error && reports.length > 0 && (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {reports.map((report) => (
              <div 
                key={report.id}
                className={`
                  rounded-lg shadow-sm overflow-hidden
                  ${darkMode 
                    ? 'bg-gray-800 border border-gray-700' 
                    : 'bg-white border border-gray-200'
                  }
                `}
              >
                <div className="p-5">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        Report #{report.id}
                      </h3>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        {new Date(report.timestamp).toLocaleString()}
                      </p>
                    </div>
                    <span className={`
                      px-2.5 py-1 text-xs font-medium rounded-full
                      ${getStatusColor(report.status, darkMode)}
                    `}>
                      {report.status}
                    </span>
                  </div>

                  <div className="space-y-3 mb-4">
                    <div>
                      <label className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        Reporter
                      </label>
                      <p className={`mt-1 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {report.reporter_id}
                      </p>
                    </div>
                    <div>
                      <label className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        Reported User
                      </label>
                      <p className={`mt-1 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {report.reported_id}
                      </p>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Reason
                    </label>
                    <p className={`mt-1 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                      {report.reason}
                    </p>
                  </div>

                  {report.evidence_url && (
                    <div className="mb-4">
                      <button 
                        onClick={() => openEvidenceModal(report.evidence_url!)}
                        className={`
                          inline-flex items-center gap-2
                          px-4 py-2 rounded-md
                          transition-all duration-200
                          ${darkMode 
                            ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' 
                            : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                          }
                          font-medium text-sm
                          hover:shadow-md
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        `}
                      >
                        <svg 
                          className="w-4 h-4" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
                          />
                        </svg>
                        Evidence
                      </button>
                    </div>
                  )}

                  {report.handler && (
                    <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                      <label className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        Handled By
                      </label>
                      <p className={`mt-1 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {report.handler}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog 
            as="div" 
            className="relative z-50"
            onClose={() => setIsModalOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/75" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className={`
                    w-full max-w-2xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all
                    ${darkMode ? 'bg-gray-800' : 'bg-white'}
                  `}>
                    <div className="flex justify-between items-start mb-4">
                      <Dialog.Title
                        as="h3"
                        className={`text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}
                      >
                        Evidence
                      </Dialog.Title>
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className={`
                          rounded-md p-1
                          ${darkMode 
                            ? 'text-gray-400 hover:text-gray-300' 
                            : 'text-gray-500 hover:text-gray-600'
                          }
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        `}
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>

                    <div className="mt-4 max-h-[70vh] overflow-hidden">
                      {selectedEvidence && (
                        isVideo(selectedEvidence) ? (
                          <ReactPlayer 
                            ref={playerRef}
                            url={selectedEvidence} 
                            controls
                            width="100%"
                            height="auto"
                            volume={volume}
                            style={{ 
                              aspectRatio: '16/9',
                              maxWidth: '100%', 
                              objectFit: 'contain',
                              position: 'relative',
                              zIndex: 1
                            }}
                            onError={(e) => {
                              console.error('Error loading video:', e);
                              showAlert('Failed to load video', 'error');
                            }}
                          />
                        ) : (
                          <img 
                            src={selectedEvidence} 
                            alt="Evidence"
                            className="w-full h-auto rounded-lg"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = '/fallback-image.png';
                              console.error('Error loading image:', e);
                            }}
                          />
                        )
                      )}
                    </div>

                    <div className="mt-6 flex justify-end items-center gap-4">
                      <input 
                        type="range" 
                        min="0" 
                        max="1" 
                        step="0.01" 
                        value={volume} 
                        onChange={handleVolumeChange}
                        className="w-24"
                      />
                      <a
                        href={selectedEvidence || '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`
                          inline-flex items-center gap-2 px-4 py-2 rounded-md
                          ${darkMode 
                            ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                          }
                          text-sm font-medium
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        `}
                      >
                        Open in New Tab
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </a>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};

const getStatusColor = (status: string, darkMode: boolean): string => {
  switch (status.toLowerCase()) {
    case 'pending':
      return darkMode 
        ? 'bg-yellow-900/30 text-yellow-300' 
        : 'bg-yellow-100 text-yellow-800';
    case 'accepted':
      return darkMode 
        ? 'bg-green-900/30 text-green-300' 
        : 'bg-green-100 text-green-800';
    case 'rejected':
      return darkMode 
        ? 'bg-red-900/30 text-red-300' 
        : 'bg-red-100 text-red-800';
    default:
      return darkMode 
        ? 'bg-gray-900/30 text-gray-300' 
        : 'bg-gray-100 text-gray-800';
  }
}; 