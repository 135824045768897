import React from 'react';
import { useAlert } from '../contexts/AlertContext';
import { motion, AnimatePresence } from 'framer-motion';

const Alert: React.FC = () => {
    const { alerts } = useAlert();

    return (
        <div className="fixed top-4 right-4 z-50 space-y-2">
            <AnimatePresence>
                {alerts.map(alert => (
                    <motion.div
                        key={alert.id}
                        initial={{ opacity: 0, y: -20, scale: 0.95 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, y: 20, scale: 0.95 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                        className={`
                            px-4 py-3 rounded-lg shadow-lg flex items-center relative overflow-hidden
                            ${
                                alert.type === 'success' ? 'bg-green-500' :
                                alert.type === 'error' ? 'bg-red-500' :
                                alert.type === 'warning' ? 'bg-yellow-500' :
                                'bg-blue-500'
                            } text-white min-w-[200px]
                        `}
                    >
                        <div className="mr-2">
                            {alert.type === 'success' && (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            )}
                            {alert.type === 'error' && (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                            {alert.type === 'warning' && (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            )}
                            {alert.type === 'info' && (
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            )}
                        </div>
                        <motion.p
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.2, delay: 0.1 }}
                        >
                            {alert.message}
                        </motion.p>
                        
                        <motion.div
                            key={`progress-${alert.id}`}
                            initial={{ scaleX: 1 }}
                            animate={{ scaleX: 0 }}
                            transition={{ duration: 3, ease: "linear" }}
                            className="absolute bottom-0 left-0 right-0 h-1 bg-white/20 origin-left"
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export default Alert; 