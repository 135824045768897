import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../contexts/AlertContext';
import { motion, AnimatePresence } from 'framer-motion';

interface BlacklistManagerProps {
  darkMode: boolean;
}

interface BlacklistedUser {
  id: string;
  username: string;
  reason: string;
  date: string;
  handler: string;
  active: boolean;
}

type FilterType = 'all' | 'active' | 'inactive';

export const BlacklistManager: React.FC<BlacklistManagerProps> = ({ darkMode }) => {
  const [blacklistedUsers, setBlacklistedUsers] = useState<BlacklistedUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<FilterType>('all');
  const [usernames, setUsernames] = useState<{ [key: string]: string }>({});
  const [failedFetches] = useState<Set<string>>(new Set());
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const fetchedRef = useRef<Set<string>>(new Set());
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [usernamesToFetch, setUsernamesToFetch] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const BATCH_SIZE = 3;
  const DELAY = 200;
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 6;

  const totalPages = Math.ceil(blacklistedUsers.length / ITEMS_PER_PAGE);
  const currentItems = blacklistedUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const fetchBlacklist = async (filterType: FilterType = filter) => {
    try {
      const apiKey = process.env.REACT_APP_API_KEY;
      
      if (!apiKey) {
        setError('API configuration error');
        return;
      }

      const headers = {
        'x-api-key': apiKey,
        'Content-Type': 'application/json'
      };

      const response = await fetch(`https://api.nxpdev.dk/api/blacklist`, {
        method: 'GET',
        headers: headers
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setBlacklistedUsers(data);
      setError(null);
      
    } catch (error) {
      console.error('Error:', error);
      showAlert('Failed to fetch blacklist', 'error');
      setError('Failed to fetch blacklist');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsername = async (userId: string) => {
    if (fetchedRef.current.has(userId) || failedFetches.has(userId)) return;
    
    fetchedRef.current.add(userId);
    
    try {
      const response = await fetch(`https://api.nxpdev.dk/api/user/info?user=${userId}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY || ''
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.username) {
          setUsernames(prev => ({
            ...prev,
            [userId]: data.username
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  };

  useEffect(() => {
    fetchBlacklist();
  }, []);

  useEffect(() => {
    const processQueue = async () => {
      if (usernamesToFetch.length === 0 || isFetching) return;
      
      setIsFetching(true);
      const batch = usernamesToFetch.slice(0, BATCH_SIZE);
      
      try {
        await Promise.all(batch.map(async userId => {
          try {
            const response = await fetch(`https://api.nxpdev.dk/api/user/info?user=${userId}`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY || ''
              }
            });
            
            if (response.ok) {
              const data = await response.json();
              if (data.username) {
                setUsernames(prev => ({
                  ...prev,
                  [userId]: data.username
                }));
              }
            }
          } catch (error) {
            console.error('Error fetching username:', error);
          }
        }));
      } finally {
        setUsernamesToFetch(prev => prev.slice(BATCH_SIZE));
        setTimeout(() => {
          setIsFetching(false);
        }, DELAY);
      }
    };

    processQueue();
  }, [usernamesToFetch, isFetching]);

  useEffect(() => {
    const newUserIds = new Set<string>();
    
    blacklistedUsers.forEach(user => {
      if (!usernames[user.id] && !usernamesToFetch.includes(user.id)) {
        newUserIds.add(user.id);
      }
      if (user.handler && !usernames[user.handler] && !usernamesToFetch.includes(user.handler)) {
        newUserIds.add(user.handler);
      }
    });

    if (newUserIds.size > 0) {
      setUsernamesToFetch(prev => [...prev, ...Array.from(newUserIds)]);
    }
  }, [blacklistedUsers]);

  const copyToClipboard = (text: string, displayName: string) => {
    navigator.clipboard.writeText(text);
    showAlert(`Copied ${displayName}'s Discord ID!`, 'success');
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch(() => {});
    }
  };

  const handlePageChange = (direction: 'next' | 'prev') => {
    const elements = document.querySelectorAll('.grid > div');
    elements.forEach((el, i) => {
      const element = el as HTMLElement;
      element.style.transition = `all 0.3s ${i * 0.1}s`;
      element.style.opacity = '0';
      element.style.transform = 'translateY(-20px)';
    });

    setTimeout(() => {
      if (direction === 'next') {
        setCurrentPage(p => Math.min(totalPages, p + 1));
      } else {
        setCurrentPage(p => Math.max(1, p - 1));
      }
    }, 300);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-7xl mx-auto px-4">
      <div className="flex justify-between items-center">
        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          Blacklist Manager
        </h2>
      </div>

      {error && (
        <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <AnimatePresence mode="wait">
          {currentItems.map((user, index) => (
            <motion.div
              key={user.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ 
                duration: 0.3,
                delay: index * 0.1,
                ease: "easeOut"
              }}
              className={`p-6 rounded-lg ${
                darkMode ? 'bg-zinc-800/50 hover:bg-zinc-800' : 'bg-white hover:bg-gray-50'
              } shadow-lg transition-all duration-200`}
            >
              <div className="flex items-start gap-4 mb-4">
                <motion.div 
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: index * 0.1 + 0.2 }}
                  className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center flex-shrink-0"
                >
                  <span className="text-blue-500 text-lg font-semibold">
                    {(usernames[user.id] || user.id).charAt(0).toUpperCase()}
                  </span>
                </motion.div>
                <div className="min-w-0 flex-1">
                  <button
                    onClick={() => copyToClipboard(user.id, usernames[user.id] || user.id)}
                    className="hover:text-blue-400 transition-colors cursor-pointer relative group text-left"
                  >
                    <h3 className={`text-xl font-semibold truncate ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                      {usernames[user.id] || user.id}
                    </h3>
                    <span className="tooltip">Copy Discord ID</span>
                  </button>
                  <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} truncate`}>
                    ID: {user.id}
                  </p>
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    user.active 
                      ? 'bg-red-500/20 text-red-400' 
                      : 'bg-green-500/20 text-green-400'
                  }`}>
                    {user.active ? 'Active' : 'Inactive'}
                  </span>
                  <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {new Date(user.date).toLocaleDateString()}
                  </span>
                </div>

                <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none text-sm`}>
                  <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} truncate`}>
                    <strong>Reason:</strong> {user.reason}
                  </p>
                  <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    <strong>Handler:</strong>{' '}
                    <button
                      onClick={() => copyToClipboard(user.handler, usernames[user.handler] || user.handler)}
                      className="hover:text-blue-400 transition-colors cursor-pointer relative group"
                    >
                      <span className="relative truncate inline-block max-w-[150px] align-bottom">
                        {usernames[user.handler] || user.handler}
                        <span className="tooltip">Copy Staff ID</span>
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <motion.div 
        className="flex justify-center gap-2 mt-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${
            darkMode 
              ? 'bg-zinc-800 text-white disabled:bg-zinc-700' 
              : 'bg-white text-gray-900 disabled:bg-gray-100'
          } disabled:cursor-not-allowed transition-colors`}
        >
          Previous
        </button>
        <span className={`px-4 py-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-lg ${
            darkMode 
              ? 'bg-zinc-800 text-white disabled:bg-zinc-700' 
              : 'bg-white text-gray-900 disabled:bg-gray-100'
          } disabled:cursor-not-allowed transition-colors`}
        >
          Next
        </button>
      </motion.div>
    </div>
  );
};

export default BlacklistManager; 