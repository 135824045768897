"use client";

import { Button, Navbar } from "flowbite-react";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link, matchRoutes } from 'react-router-dom';
import api from '../utils/api';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface NavbarProps {
  darkMode: boolean;
}

const routes = [
  { path: '/' },
  { path: '/bots' },
  { path: '/dashboard' },
  { path: '/dashboard/staff' },
  { path: '/dashboard/admin' },
  { path: '/auth/callback' }
];

export const Component: React.FC<NavbarProps> = ({ darkMode }) => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const [isStaff, setIsStaff] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserAvatar = async () => {
      if (isAuthenticated) {
        const token = localStorage.getItem('discord_token');
        try {
          const response = await fetch('https://discord.com/api/users/@me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          const avatarUrl = `https://cdn.discordapp.com/avatars/${data.id}/${data.avatar}.png`;
          setUserAvatar(avatarUrl);
        } catch (error) {
          console.error('Error fetching user avatar:', error);
        }
      }
    };

    fetchUserAvatar();
  }, [isAuthenticated]);

  useEffect(() => {
    const checkStaffStatus = async () => {
      try {
        const response = await api.get('/auth/staff');
        setIsStaff(response.data.isStaff);
      } catch (error) {
        console.error('Error checking staff status:', error);
        setIsStaff(false);
      }
    };

    checkStaffStatus();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await api.get('/auth/admin');
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('discord_token');
    localStorage.removeItem('discord_user_id');
    localStorage.removeItem('isAuthenticated');
    setUserAvatar(null);
    navigate('/');
  };

  const handleLogin = () => {
    const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
    const REDIRECT_URI = `${window.location.origin}/auth/callback`;
    const scope = 'identify';
    const discordUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${scope}`;
    window.location.href = discordUrl;
  };

  const location = useLocation();

  const isActive = (path: string) => {
    const matches = matchRoutes(routes, location.pathname);

    if (!matches) {
      return false;
    }

    if (path === '/') {
      return location.pathname === '/';
    }
    
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const defaultAvatar = "https://cdn.discordapp.com/embed/avatars/0.png";

  const tooltipClasses = `
    absolute -bottom-12 left-1/2 -translate-x-1/2 
    hidden group-hover:block 
    w-max px-3 py-2 
    bg-zinc-800 text-white text-sm 
    rounded-lg shadow-lg 
    border border-zinc-700
    opacity-0 group-hover:opacity-100 
    transition-all duration-300 ease-in-out
    before:content-[''] 
    before:absolute before:-top-1 
    before:left-1/2 before:-translate-x-1/2 
    before:w-2 before:h-2 
    before:bg-zinc-800 
    before:border-l before:border-t before:border-zinc-700
    before:rotate-45
  `;

  const renderUserSection = () => {
    return (
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500">
            {isAuthenticated ? (
              <img
                className="h-8 w-8 rounded-full"
                src={userAvatar || undefined}
                alt="User avatar"
              />
            ) : (
              <div className="h-8 w-8 rounded-full bg-[#5865F2] flex items-center justify-center">
                <i className="fas fa-user text-white text-sm" />
              </div>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`absolute right-1/2 translate-x-1/2 mt-2 w-48 origin-top rounded-md shadow-lg 
            ${darkMode ? 'bg-zinc-800' : 'bg-white'} ring-1 ring-black ring-opacity-5 focus:outline-none`}>
            {isAuthenticated ? (
              <>
                {isAdmin && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/dashboard/admin"
                        className={`${
                          active 
                            ? darkMode 
                              ? 'bg-zinc-700 text-white' 
                              : 'bg-gray-100 text-gray-900'
                            : darkMode
                              ? 'text-gray-300'
                              : 'text-gray-700'
                        } group flex items-center px-4 py-2 text-sm`}
                      >
                        <i className="fas fa-gauge-high mr-3 h-5 w-5" aria-hidden="true" />
                        Admin Dashboard
                      </Link>
                    )}
                  </Menu.Item>
                )}
                
                {isStaff && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/dashboard/staff"
                        className={`${
                          active 
                            ? darkMode 
                              ? 'bg-zinc-700 text-white' 
                              : 'bg-gray-100 text-gray-900'
                            : darkMode
                              ? 'text-gray-300'
                              : 'text-gray-700'
                        } group flex items-center px-4 py-2 text-sm`}
                      >
                        <i className="fas fa-user-shield mr-3 h-5 w-5" aria-hidden="true" />
                        Staff Panel
                      </Link>
                    )}
                  </Menu.Item>
                )}

                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleLogout}
                      className={`${
                        active 
                          ? darkMode 
                            ? 'bg-zinc-700 text-white' 
                            : 'bg-gray-100 text-gray-900'
                          : darkMode
                            ? 'text-gray-300'
                            : 'text-gray-700'
                      } group flex w-full items-center px-4 py-2 text-sm`}
                    >
                      <i className="fas fa-sign-out-alt mr-3 h-5 w-5" aria-hidden="true" />
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </>
            ) : (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleLogin}
                    className={`${
                      active 
                        ? darkMode 
                          ? 'bg-zinc-700 text-white' 
                          : 'bg-gray-100 text-gray-900'
                        : darkMode
                          ? 'text-gray-300'
                          : 'text-gray-700'
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <i className="fab fa-discord mr-3 h-5 w-5" aria-hidden="true" />
                    Login with Discord
                  </button>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <Navbar fluid rounded className="dark:bg-zinc-900">
      <div className="max-w-screen-xl mx-auto w-full flex flex-wrap items-center justify-between">
        <Navbar.Brand>
          <img src={"https://noxitech.dk/cdn/images/nxpdev.png"} className="mr-3 h-6 sm:h-9" alt="NxP Logo" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">NxP</span>
        </Navbar.Brand>
        <div className="flex md:order-2 items-center gap-6">
          <div className="hidden md:flex items-center gap-6">

            {renderUserSection()}
          </div>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse className="w-full md:w-auto md:flex-1">
          <div className="flex flex-col md:flex-row md:justify-center md:items-center w-full space-y-4 md:space-y-0 md:space-x-4">
            <Navbar.Link href="/" active={isActive('/')}>
              Home
            </Navbar.Link>
            <Navbar.Link href="/bots" active={isActive('/bots')}>
              Bots
            </Navbar.Link>
            <Navbar.Link href="/dashboard" active={isActive('/dashboard')}>
              Dashboard
            </Navbar.Link>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}