import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PageTransition } from './PageTransition';

interface TermsSection {
  id: number;
  title: string;
  content: string;
  order: number;
}

interface TermsOfServiceProps {
  darkMode: boolean;
}

export const TermsOfService: React.FC<TermsOfServiceProps> = ({ darkMode }) => {
  const [sections, setSections] = useState<TermsSection[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await axios.get<TermsSection[]>('https://api.nxpdev.dk/terms/sections');
        setSections(response.data);
      } catch (error) {
        console.error('Error fetching terms sections:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSections();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <PageTransition>
      <div className={`min-h-screen ${darkMode ? 'bg-zinc-900' : 'bg-gray-100'} py-12`}>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className={`text-4xl font-bold mb-8 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
            Terms of Service
          </h1>

          <div className={`space-y-8 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {sections.map((section) => (
              <section key={section.id} className="space-y-4">
                <h2 className={`text-2xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h2>
                <ReactMarkdown 
                  remarkPlugins={[remarkGfm]}
                  className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none 
                    prose-headings:font-bold prose-h1:text-2xl prose-h2:text-xl prose-h3:text-lg
                    prose-a:text-blue-600 hover:prose-a:text-blue-500
                    prose-p:leading-relaxed
                    prose-ul:list-disc prose-ol:list-decimal
                    prose-li:ml-4
                    prose-pre:bg-gray-800 prose-pre:p-4 prose-pre:rounded-lg
                    prose-code:text-pink-500 prose-code:bg-gray-100 prose-code:px-1 prose-code:rounded
                    ${darkMode ? 'prose-code:bg-zinc-800' : 'prose-code:bg-gray-100'}
                  `}
                >
                  {section.content}
                </ReactMarkdown>
              </section>
            ))}

            <section className="pt-8 text-sm">
              <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Last updated: {new Date().toLocaleDateString()}
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}; 