import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const code = new URLSearchParams(window.location.search).get('code');
      
      if (code) {
        try {
          const apiKey = process.env.REACT_APP_API_KEY;
          if (!apiKey) {
            console.error('API key is missing');
            navigate('/');
            return;
          }

          console.log('Sending auth request to backend...');
          const response = await fetch('https://api.nxpdev.dk/auth/discord', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey
            },
            body: JSON.stringify({ code })
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error('Auth error:', response.status, errorText);
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log('Auth response received:', data);
          
          if (data.access_token) {
            localStorage.setItem('discord_token', data.access_token);
            localStorage.setItem('discord_user_id', data.user_id);
            localStorage.setItem('isAuthenticated', 'true');
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Auth error:', error);
          navigate('/');
        }
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl mb-4">Logging you in...</h2>
      </div>
    </div>
  );
};