import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useAlert } from '../../contexts/AlertContext';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MarkdownToolbar } from '../common/MarkdownToolbar';
import { ChangelogEditor } from './ChangelogEditor';
import { Changelog } from '../../types/Changelog';
import { ConfirmationModal } from '../common/ConfirmationModal';

interface ChangelogManagerProps {
  darkMode: boolean;
}

export const ChangelogManager: React.FC<{ darkMode: boolean }> = ({ darkMode }) => {
  const [changelogs, setChangelogs] = useState<Changelog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showAlert } = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 6;
  const [mode, setMode] = useState<'list' | 'create' | 'edit'>('list');
  const [editingChangelog, setEditingChangelog] = useState<Changelog | null>(null);
  const [previewText, setPreviewText] = useState('');
  const [changelogToDelete, setChangelogToDelete] = useState<Changelog | null>(null);

  const totalPages = Math.ceil((changelogs?.length || 0) / ITEMS_PER_PAGE);
  const currentItems = changelogs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const fetchChangelogs = async () => {
    try {
      const response = await axios.get('https://api.nxpdev.dk/changelogs');
      setChangelogs(response.data);
      setError(null);
    } catch (error) {
      console.error('Error:', error);
      showAlert('Failed to fetch changelogs', 'error');
      setError('Failed to fetch changelogs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChangelogs();
  }, []);

  const handlePageChange = (direction: 'next' | 'prev') => {
    const elements = document.querySelectorAll('.grid > div');
    elements.forEach((el, i) => {
      const element = el as HTMLElement;
      element.style.transition = `all 0.3s ${i * 0.1}s`;
      element.style.opacity = '0';
      element.style.transform = 'translateY(-20px)';
    });

    setTimeout(() => {
      if (direction === 'next') {
        setCurrentPage(p => Math.min(totalPages, p + 1));
      } else {
        setCurrentPage(p => Math.max(1, p - 1));
      }
    }, 300);
  };

  const handleAddChangelog = async (changelog: Omit<Changelog, 'id' | 'created_at'>) => {
    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        showAlert('Authentication required', 'error');
        return;
      }

      await axios.post('https://api.nxpdev.dk/admin/changelog', changelog, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey
        }
      });

      showAlert('Changelog added successfully', 'success');
      setMode('list');
      fetchChangelogs();
    } catch (error) {
      console.error('Error adding changelog:', error);
      showAlert('Failed to add changelog', 'error');
    }
  };

  const handleEditChangelog = (changelog: Changelog) => {
    setEditingChangelog(changelog);
    setMode('edit');
  };

  const handleDeleteChangelog = async () => {
    if (!changelogToDelete) return;

    try {
      const token = localStorage.getItem('discord_token');
      const apiKey = process.env.REACT_APP_API_KEY;

      if (!token || !apiKey) {
        showAlert('Authentication required', 'error');
        return;
      }

      await axios.delete(`https://api.nxpdev.dk/admin/changelog/${changelogToDelete.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': apiKey
        }
      });

      showAlert('Changelog deleted successfully', 'success');
      setChangelogToDelete(null);
      fetchChangelogs();
    } catch (error) {
      console.error('Error deleting changelog:', error);
      showAlert('Failed to delete changelog', 'error');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-7xl mx-auto px-4">
      {mode === 'list' ? (
        <>
          <div className="flex justify-between items-center">
            <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              Changelog Manager
            </h2>
            <button
              onClick={() => setMode('create')}
              className={`px-4 py-2 rounded-lg ${
                darkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white transition-colors`}
            >
              <i className="fas fa-plus mr-2"></i>
              Add Changelog
            </button>
          </div>

          {error && (
            <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <AnimatePresence mode="wait">
              {currentItems.map((changelog, index) => (
                <motion.div
                  key={changelog.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ 
                    duration: 0.3,
                    delay: index * 0.1,
                    ease: "easeOut"
                  }}
                  className={`p-6 rounded-lg relative ${
                    darkMode ? 'bg-zinc-800/50 hover:bg-zinc-800' : 'bg-white hover:bg-gray-50'
                  } shadow-lg transition-all duration-200`}
                >
                  <div className="flex items-start gap-4 mb-4 mr-20">
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: index * 0.1 + 0.2 }}
                      className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center flex-shrink-0"
                    >
                      <span className="text-blue-500 text-lg font-semibold">
                        {changelog.id}
                      </span>
                    </motion.div>
                    <div className="min-w-0 flex-1">
                      <h3 className={`text-xl font-semibold truncate ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {changelog.title}
                      </h3>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} truncate`}>
                        Version: {changelog.version}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className={`px-3 py-1 rounded-full text-sm ${
                        changelog.type === 'FEATURE' ? 'bg-green-500/20 text-green-400' :
                        changelog.type === 'BUGFIX' ? 'bg-red-500/20 text-red-400' :
                        changelog.type === 'IMPROVEMENT' ? 'bg-blue-500/20 text-blue-400' :
                        'bg-yellow-500/20 text-yellow-400'
                      }`}>
                        {changelog.type}
                      </span>
                      <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {new Date(changelog.created_at).toLocaleDateString()}
                      </span>
                    </div>

                    <div className={`prose ${darkMode ? 'prose-invert' : ''} max-w-none text-sm`}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {changelog.description}
                      </ReactMarkdown>
                    </div>
                  </div>

                  <div className="absolute top-4 right-4 flex flex-col space-y-2">
                    <button
                      onClick={() => handleEditChangelog(changelog)}
                      className={`px-3 py-1 rounded-lg ${
                        darkMode 
                          ? 'bg-zinc-700 hover:bg-zinc-600 text-gray-300' 
                          : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                      } transition-colors`}
                    >
                      <i className="fas fa-edit"></i> Edit
                    </button>
                    <button
                      onClick={() => setChangelogToDelete(changelog)}
                      className={`px-3 py-1 rounded-lg ${
                        darkMode 
                          ? 'bg-red-700 hover:bg-red-600 text-gray-300' 
                          : 'bg-red-100 hover:bg-red-200 text-red-700'
                      } transition-colors`}
                    >
                      <i className="fas fa-trash"></i> Delete
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>

          <motion.div 
            className="flex justify-center gap-2 mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <button
              onClick={() => handlePageChange('prev')}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${
                darkMode 
                  ? 'bg-zinc-800 text-white disabled:bg-zinc-700' 
                  : 'bg-white text-gray-900 disabled:bg-gray-100'
              } disabled:cursor-not-allowed transition-colors`}
            >
              Previous
            </button>
            <span className={`px-4 py-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange('next')}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${
                darkMode 
                  ? 'bg-zinc-800 text-white disabled:bg-zinc-700' 
                  : 'bg-white text-gray-900 disabled:bg-gray-100'
              } disabled:cursor-not-allowed transition-colors`}
            >
              Next
            </button>
          </motion.div>
        </>
      ) : (
        <ChangelogEditor
          darkMode={darkMode}
          changelog={editingChangelog}
          onSave={() => {
            setMode('list');
            fetchChangelogs();
          }}
          onCancel={() => setMode('list')}
        />
      )}
      <ConfirmationModal
        isOpen={!!changelogToDelete}
        onClose={() => setChangelogToDelete(null)}
        onConfirm={handleDeleteChangelog}
        message="Are you sure you want to delete this changelog?"
      />
    </div>
  );
};

export default ChangelogManager;