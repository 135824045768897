import React from 'react';
import { Button } from 'flowbite-react';

interface LoginButtonProps {
  darkMode: boolean;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ darkMode }) => {
  const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const REDIRECT_URI = `${window.location.origin}/auth/callback`;
  
  const handleLogin = () => {
    const scope = 'identify';
    const discordUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${scope}`;
    console.log('Redirecting to:', discordUrl);
    window.location.href = discordUrl;
  };

  return (
    <div className="flex justify-center">
      <Button
        onClick={handleLogin}
        color={darkMode ? 'dark' : 'light'} 
        className="text-white px-8 py-2"
      >
        Login with Discord
      </Button>
    </div>
  );
};